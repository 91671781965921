import React, { useEffect } from "react";
import { useAnimate, motion, useInView } from "framer-motion";
import { FAQItem } from "../FAQItem";
import { TextT } from "../../utils/contentfulTypes";
import { ContentfulTextContainer } from "../ContentfulTextContainer";

interface HomepageFaqProps {
  items: TextT[];
}

export const HomepageFaq: React.FC<HomepageFaqProps> = ({ items }) => {
  const [headerRef, animate] = useAnimate();
  const isInView = useInView(headerRef, {
    amount: "all",
  });

  useEffect(() => {
    if (isInView) {
      animate(
        headerRef.current,
        { opacity: 1, translateY: 0 },
        {
          duration: 0.5,
          ease: "easeOut",
        }
      );
    }
  }, [isInView]);

  return (
    <section className="pt-10 pb-7 px-4 rounded-t-3xl bg-[#F6F6F1] md:px-20 md:rounded-none">
      <div className="width-1440">
        <motion.h2
          ref={headerRef}
          className="w-[14ch] modernGothicTrial-light text-[28px] md:text-[48px] md:leading-snug md:mb-11"
          initial={{ opacity: 0, translateY: -60 }}
        >
          Have questions?{" "}
          <span className="text-[#6F5CBC]">We have answers</span>
        </motion.h2>
        {items.map((item) => (
          <FAQItem
            key={item.headline}
            headline={item.headline}
            content={
              <div className="modernGothicTrial-light">
                <ContentfulTextContainer markdown={item.text} />
              </div>
            }
          />
        ))}
      </div>
    </section>
  );
};
