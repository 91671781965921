import Image from "next/image";
import Link from "next/link";
import { CardT, TextT } from "../../utils/contentfulTypes";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getTailwindBreakpoints } from "../../utils/misc";
import { useAnimate, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

interface CardProps {
  data: CardT;
  isMobile: boolean;
}

const Card: React.FC<CardProps> = ({ data, isMobile }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(cardRef, {
    amount: 0.6,
  });
  const [headerRef, animateHeader] = useAnimate();
  const [textRef, animateText] = useAnimate();

  useEffect(() => {
    const animationDuration = 0.5;
    if (isInView) {
      animateHeader(
        headerRef.current,
        { opacity: 1 },
        {
          duration: animationDuration,
          ease: "easeIn",
        }
      );
      animateText(
        textRef.current,
        { opacity: 1 },
        {
          duration: animationDuration,
          ease: "easeIn",
          delay: animationDuration,
        }
      );
    }
  }, [isInView]);

  return (
    <div
      ref={cardRef}
      className="relative max-h-[85dvh] h-[566px] w-full rounded-3xl"
    >
      <Image
        src={isMobile ? data.imageMobile.url : data.image.url}
        alt={"alter alt"}
        fill
        className="rounded-3xl object-cover"
      />
      <h3
        ref={headerRef}
        className="absolute top-6 left-3 w-[15ch] modernGothicTrial-light text-[32px] leading-relaxed text-alter-bone md:top-16 md:left-16 md:w-full md:text-[40px]"
        style={{ opacity: 0 }}
      >
        {data.headline}
      </h3>
      <div
        ref={textRef}
        className="flex flex-col gap-7 absolute bottom-6 left-3 md:bottom-16 md:left-16"
        style={{ opacity: 0 }}
      >
        <p className="hidden md:block w-[32ch] text-xl modernGothicTrial-light antialiased text-alter-bone">
          {data.text}
        </p>
        <Link
          href={data.buttonLink}
          className="block w-max button-secondary text-base"
        >
          {data.buttonText}
        </Link>
      </div>
    </div>
  );
};

interface HowAlterWorksProps {
  headline: string;
  items: CardT[];
  quote: TextT;
}

export const HowAlterWorks: React.FC<HowAlterWorksProps> = ({
  headline,
  items,
  quote,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = !!width && width < getTailwindBreakpoints("md");
  return (
    <section className="pb-16 px-4 rounded-b-3xl md:rounded-none bg-[#F6F6F1] md:px-20">
      <div className="width-1440">
        <h2 className="modernGothicTrial-light antialiased text-[28px] text-center md:text-[56px]">
          {headline}
        </h2>
        <div className="flex flex-col gap-10 mt-10 md:mt-[60px]">
          {items.map((item) => (
            <Card key={item.headline} data={item} isMobile={isMobile} />
          ))}
        </div>
        <div className="pt-16 bg-[#F6F6F1] text-center">
          <p className="max-w-[48ch] mx-auto text-2xl modernGothicTrial-light md:text-[32px] md:leading-snug">
            {quote.headline}
          </p>
          <div className="mt-6 modernGothicTrial-light text-alter-black-80 text-[20px]">
            {quote.subheadline}
          </div>
        </div>
      </div>
    </section>
  );
};
