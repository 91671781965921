import Image from "next/image";
import React, { useEffect } from "react";

import Link from "next/link";
import clsx from "clsx";
import { CardT } from "../../utils/contentfulTypes";
import { motion, useAnimate, useInView } from "framer-motion";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface SubsectionProps {
  header: React.ReactNode | string;
  text: string;
  link: string;
  linkLabel: string;
  image: string;
  reverse?: boolean;
}

const Subsection = ({
  header,
  image,
  link,
  linkLabel,
  text,
  reverse,
}: SubsectionProps) => {
  const [headerRef, animate] = useAnimate();
  const isInView = useInView(headerRef, {
    amount: "all",
  });

  useEffect(() => {
    if (isInView) {
      animate(
        headerRef.current,
        { opacity: 1, translateY: 0 },
        {
          duration: 0.5,
          ease: "easeOut",
        }
      );
    }
  }, [isInView]);

  return (
    <div
      className={clsx(
        "flex flex-col gap-8 lg:gap-[100px] md:items-center",
        reverse ? "lg:flex-row-reverse" : "lg:flex-row"
      )}
    >
      <Image
        src={image}
        alt={"alter alt"}
        width={500}
        height={400}
        className="w-full rounded-3xl md:basis-2/5"
      />
      <div className=" md:basis-3/5">
        <motion.h2
          ref={headerRef}
          className="modernGothicTrial-light text-[28px] md:text-[48px] md:leading-snug"
          initial={{ opacity: 0, translateY: 60 }}
        >
          {header}
        </motion.h2>
        <p className="mt-4 text-sm text-alter-black-80 modernGothicTrial-light md:text-xl">
          {text}
        </p>
        <Link href={link} className="block w-max button-outline-dark mt-10">
          {linkLabel}
        </Link>
      </div>
    </div>
  );
};

interface RealFitnessCoachProps {
  items: CardT[];
}

export const RealFitnessCoach: React.FC<RealFitnessCoachProps> = ({
  items,
}) => {
  return (
    <section className="md:bg-[#F6F6F1]">
      <div className="py-12 px-4 md:px-20 bg-white md:rounded-3xl">
        <div className="width-1440">
          <div className="flex flex-col gap-10">
            {items.map((item, index) => (
              <Subsection
                key={item.headline}
                header={
                  index === 0 ? (
                    <>
                      Access to Best in Class{" "}
                      <span className="text-[#C6410A]">Fitness Coaches</span>
                    </>
                  ) : (
                    item.headline
                  )
                }
                text={item.text}
                link={item.buttonLink}
                linkLabel={item.buttonText}
                image={item.image.url}
                reverse={index % 2 === 0}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
