import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

interface FAQItemProps {
  headline: string | React.ReactNode;
  content: React.ReactNode;
  iconSize?: "sm" | "md" | "lg";
  iconVariant?: "x" | "minus" | "circled-x";
  dark?: boolean;
  containerClassName?: string;
  headerClassName?: string;
  onInteract?: (open: boolean) => void;
  forceOpen?: boolean;
}

const iconSizeMap = {
  sm: "w-3 h-3",
  md: "w-4 h-4",
  lg: "w-6 h-6",
};

export const FAQItem = ({
  headline,
  content,
  iconSize = "md",
  iconVariant = "circled-x",
  dark,
  containerClassName = "[&_h3]:modernGothicTrial-light last:border-b-0",
  headerClassName = "md:text-xl",
  onInteract,
  forceOpen
}: FAQItemProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (forceOpen === true) {
      setOpen(true);
    }
  }, [forceOpen]);
  
  return (
    <div
      onClick={() => {
        setOpen(!open);
        onInteract && onInteract(!open);
      }}
      className={clsx(
        "border-b last:border-b-0 py-7 cursor-pointer",
        dark ? "border-b-alter-bone-10" : "border-b-alter-black-20",
        containerClassName
      )}
    >
      <div className="flex items-center gap-2">
        <h3 className={"grow font-light text-lg " + headerClassName}>
          {headline}
        </h3>
        <div
          className={
            iconVariant === "circled-x"
              ? "rounded-full p-3 bg-alter-black-4"
              : ""
          }
        >
          <div
            className={clsx(
              "relative flex aspect-square items-center justify-center",
              iconSizeMap[iconSize]
            )}
          >
            <div
              className={clsx(
                "absolute h-[2px] w-full bg-black transition-transform",
                dark ? "bg-alter-bone" : "bg-alter-black",
                open && (iconVariant === "x" || iconVariant === "circled-x")
                  ? "rotate-45"
                  : ""
              )}
            />
            <div
              className={clsx(
                "absolute h-full w-[2px] bg-black transition-transform ease-in-out",
                open
                  ? iconVariant === "x" || iconVariant === "circled-x"
                    ? "rotate-45"
                    : "rotate-90"
                  : "",
                dark ? "bg-alter-bone" : "bg-alter-black"
              )}
            />
          </div>
        </div>
      </div>

      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className={`overflow-hidden  text-alter-black-80`}
          >
            <div className="pt-4 font-light">{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
